<template>
  <div
    class="relative w-screen h-screen bg-dark flex flex-col items-center overflow-hidden"
  >
    <Logo class="h-20 mt-72 w-20 sm:h-24 sm:w-24" />
    <h1>
      <img
        src="/Almana.cc.svg"
        alt="Almana.cc"
        class="h-10 rotate-[5deg] fill-black sm:h-16 sm:-mt-2"
      />
    </h1>
    <img
      class="absolute top-[-2%] right-0 h-[25%] rotate-45"
      src="/blob-yellow.svg"
      alt="Yellow Blob"
    />
    <img
      class="absolute bottom-[20%] left-[-2%] h-[25%]"
      src="/blob-red.svg"
      alt="Red Blob"
    />
    <img
      class="absolute bottom-[-10%] right-[-27%] h-[28%] sm:right-[-15%] lg:right-[-7%]"
      src="/blob-blue.svg"
      alt="Blue Blob Da Ba Dee"
    />
    <h2 class="hidden">Discover history, one day at a time</h2>
    <div class="mt-10 px-4 w-full z-10">
      <UProgress :value="progressValue" :max="steps" :color="progressColor" />
    </div>
  </div>
</template>

<script setup>
import { Preferences } from '@capacitor/preferences'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import {
  ScreenOrientation,
  OrientationType,
} from '@capawesome/capacitor-screen-orientation'
import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import useSettings from '~/composables/useSettings'

CapacitorUpdater.notifyAppReady()

useStatusBar()
const { steps, progressValue, progressColor, nextStep } = useProgressSteps(500)

onMounted(async () => {
  // Push Notifications after a prediction is created
  await useSettings().initPush()
  // Handle back button
  await App.addListener('backButton', (event) => {
    if (event.canGoBack) window.history.back()
    else App.exitApp()
  })

  // Reminder notifications + orientation lock + connection check
  await nextStep()
  if (Capacitor.isNativePlatform()) {
    await useSettings().proposeReminder()
    await ScreenOrientation.lock({ type: OrientationType.PORTRAIT })
    await useConnection()
  }

  // Redirects
  await nextStep()
  const { value: redirect } = await Preferences.get({ key: 'redirect' })
  if (redirect) {
    await Preferences.remove({ key: 'redirect' })
    await navigateTo(redirect, { replace: true })
    return
  }

  // Fetch pending predictions
  await nextStep()
  const { processPending } = await usePrediction()
  await processPending()

  // route user to today submission or camera
  await nextStep()
  if (!(await useSettings().initMedias())) {
    return
  }
  const { getMediasByDate } = await useMedias()
  const today = new Date().toISOString().slice(0, 10) // date util ?
  const hasTodaySubmission = getMediasByDate(today)?.length > 0
  if (hasTodaySubmission) {
    await useLocalNotifications().deleteNotification(today)
    await navigateTo('/calendrier', { replace: true })
  } else await navigateTo(`/theme/${today}/camera`, { replace: true })
})

onUnmounted(async () => {
  await App.removeAllListeners()
})
</script>

import { Network } from '@capacitor/network'

export default async function () {
  const connected = ref((await Network.getStatus()).connected)
  await Network.addListener('networkStatusChange', (status) => {
    connected.value = status.connected
  })

  const toast = useToast()
  watch(
    connected,
    (isCo) => {
      if (isCo) toast.remove('connection')
      else {
        toast.add({
          id: 'connection',
          color: 'failure',
          icon: 'i-heroicons-hand-thumb-down-20-solid',
          title: 'Pas de connection',
          description: "L'app marche mieux quand on est connecté",
          timeout: 0,
        })
      }
    },
    { immediate: true },
  )
}

import { Capacitor } from '@capacitor/core'
import { StatusBar } from '@capacitor/status-bar'

async function hideStatusBar() {
  if (Capacitor.isNativePlatform()) {
    try {
      await StatusBar.hide()
      if (Capacitor.getPlatform() === 'android')
        await StatusBar.setOverlaysWebView({ overlay: true })
    } catch (error) {
      console.error('Erreur lors de la gestion de la barre de statut:', error)
    }
  }
}

export default function () {
  onMounted(async () => {
    await hideStatusBar()
  })
}

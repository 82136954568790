import { LocalNotifications } from '@capacitor/local-notifications'
import type { ThemeInfos } from '~/repository/theme'

const displayDate = (date: Date) =>
  date.toLocaleString('fr-FR', { day: '2-digit', month: 'long' })
const createNotification = (
  date: Date,
  dateString: string,
  theme: ThemeInfos | undefined,
) => {
  const title = theme
    ? `Explorez ce qui s'est passé le ${displayDate(date)} ${theme.year}`
    : `Découvrez le thème du ${displayDate(date)}`
  return {
    id: parseInt(dateString.replaceAll('-', '')),
    title: title,
    body: 'Cliquez pour en savoir plus sur le thème du jour!',
    schedule: {
      at: new Date(date.setHours(13, 0, 0, 0)),
    },
  }
}

export default () => {
  return {
    init: async () => {
      const { $appFetch } = useNuxtApp()
      const { display } = await LocalNotifications.checkPermissions()
      if (display === 'granted') {
        const { data: themes } = await $appFetch('/api/themeList')
        const today = new Date()
        const notifications = []
        for (let i = 0; i < 7; i++) {
          const date = new Date(today.getTime() + i * 24 * 60 * 60 * 1000)
          const dateString = date.toISOString().slice(0, 10)
          const theme = themes?.value?.find(
            (theme: ThemeInfos) => theme.date === dateString,
          )
          if (i === 0 && today.getHours() >= 13) {
            continue
          }
          notifications.push(createNotification(date, dateString, theme))
        }

        await LocalNotifications.schedule({
          notifications,
        })
      }
    },
    deleteNotification: async (dateString: string) => {
      await LocalNotifications.cancel({
        notifications: [{ id: parseInt(dateString.replaceAll('-', '')) }],
      })
    },
    deleteAll: async () => {
      const { notifications: old } = await LocalNotifications.getPending()
      LocalNotifications.cancel({
        notifications: old.map(({ id }) => ({ id })),
      })
    },
  }
}

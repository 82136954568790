const stepsMessages = [
  [
    'Initialisation des circuits 🛠️',
    'Réveil des serveurs 💻',
    'Chargement des données 📊',
    'Synchronisation des bits 🧬',
    'En voiture Simone! 👩‍✈️',
  ],
  [
    'Préparation des moteurs 🚀',
    'Connexion aux satellites 🛰️',
    'Analyse des signaux 📡',
    'Compilation des résultats 📝',
    'Prêt au décollage! 🛫',
  ],
  [
    'Activation des capteurs 🔍',
    'Mise en route des systèmes ⚙️',
    'Collecte des informations 🗂️',
    'Traitement des données 🧮',
    'Départ imminent! 🚦',
  ],
  [
    'Démarrage des protocoles 📜',
    'Vérification des paramètres ✅',
    'Chargement des modules 🧩',
    'Synchronisation des horloges ⏰',
    'En avant toute! 🚢',
  ],
  [
    'Initialisation des composants 🧱',
    'Test des connexions 🔌',
    'Chargement des fichiers 📂',
    'Analyse des performances 📈',
    'Prêt à partir! 🏁',
  ],
  [
    'Configuration des réseaux 🌐',
    'Lancement des scripts 🖥️',
    'Vérification des données 🔍',
    'Optimisation des processus 🔧',
    "C'est parti! 🏎️",
  ],
  [
    'Préparation des bases de données 🗄️',
    'Initialisation des variables 📊',
    'Chargement des ressources 📦',
    'Compilation des scripts 📝',
    'En route! 🚗',
  ],
  [
    'Initialisation des systèmes de sécurité 🔒',
    'Vérification des accès 🔑',
    'Chargement des protocoles de sécurité 🛡️',
    'Analyse des vulnérabilités 🐞',
    'Sécurité maximale! 🛡️',
  ],
  [
    'Préparation des environnements de test 🧪',
    'Lancement des tests unitaires 🧩',
    'Analyse des résultats de test 📊',
    'Correction des erreurs 🛠️',
    'Tests réussis! ✅',
  ],
  [
    'Configuration des serveurs de production 🏭',
    'Déploiement des applications 🚀',
    'Vérification des déploiements 🔍',
    'Optimisation des performances 🚄',
    'Production en cours! 🏭',
  ],
  [
    'Préparation des rapports 📑',
    'Compilation des données 📊',
    'Analyse des résultats 📈',
    'Rédaction des conclusions 📝',
    'Rapport finalisé! 📑',
  ],
]

export default function (delayBeforeNextStep: number = 0) {
  const progress = ref(0)
  const steps = ref(['...'])
  const progressColor = computed(() => {
    switch (progress.value) {
      case 1:
        return 'failure'
      case 2:
        return 'orange'
      case 3:
        return 'info'
      case 4:
        return 'success'
      default:
        return 'pink'
    }
  })

  onMounted(() => {
    const randomIndex = Math.floor(Math.random() * stepsMessages.length)
    steps.value = stepsMessages[randomIndex]
  })

  return {
    progressValue: computed(() => progress.value),
    progressColor,
    steps,
    nextStep: async () => {
      await new Promise((done) => setTimeout(done, delayBeforeNextStep))
      progress.value = progress.value + 1
    },
  }
}
